import { InferType, array, boolean, number, object, tuple } from 'yup';
import { SplitSettings } from './system-settings.dto';

export const systemSettingsSchema = object({
  edit_warehouse_position_num: number().nullable(),
  inventory_task_product_types: number().nullable(),
  setting_prohibition_without_labels: boolean().nullable(),
  edit_position_is_option: boolean().nullable(),
  report_include_invalid_information: boolean().nullable(),
  number_orders_single_distribution_task: number().nullable(),
  number_sku_single_distribution_task: number().nullable(),
  number_many_orders_single_distribution_task: number()
    .nullable()
    .when('split_setting', ([settings], schema) => {
      if (settings.length === 0) {
        return schema;
      }

      const min = Math.max(
        ...(settings as SplitSettings[]).map((item) => item.order_num),
      );

      return schema.min(Math.min(min, 200));
    }),
  collaborate_many_orders_single_distribution_task: number().nullable(),
  weight_range: tuple([number().nullable(), number().nullable()]).test(
    'validRange',
    '最小值不能大于最大值',
    (value) => {
      if (value?.[0] == null || value[1] == null) {
        return true;
      }

      const [min, max] = value;

      return min <= max;
    },
  ),
  weight_verification: number().nullable(),
  shipment_require_verification: boolean().nullable(),
  limitation_handle_task_send_goods: boolean().nullable(),
  limitation_handle_task_distribution: boolean().nullable(),
  number_of_tasks_listed: number().nullable(),
  modify_the_inventory_quantity: boolean().nullable(),
  quality_inspection_enables_weight_interval_radio: boolean().nullable(),
  quality_inspection_enables_weight_interval: tuple([
    number().nullable(),
    number().nullable(),
  ]).test('validRange', '最小值不能大于最大值', (value) => {
    if (value?.[0] == null || value[1] == null) {
      return true;
    }

    const [min, max] = value;

    return min <= max;
  }),
  split_setting: array(
    object({
      range: tuple([
        number().nullable().required(),
        number().nullable().required(),
      ])
        .test('validRange', '最小值不能大于最大值', (value) => {
          if (value?.[0] == null || value[1] == null) {
            return true;
          }

          const [min, max] = value;

          return min <= max;
        })
        .required(),
      order_num: number().required(),
    }),
  ),
});

export type SystemSettingsValues = InferType<typeof systemSettingsSchema>;
