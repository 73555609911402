import axios from 'axios';
import { SkuDto, SkuParams } from './sku.dto';

export class SkuService {
  async getSkuList(params: SkuParams): Promise<SkuDto[]> {
    if (
      !params.sku_title &&
      !params.warehouse_shelf_position_code &&
      !params.sku_code
    ) {
      return [];
    }

    if (params.sku_code) {
      params.sku_code = this.tryPaseSkuCode(params.sku_code);
    }

    const { data } = await axios.get<SkuDto[]>('api/wms/sku/sku_warehouse', {
      params,
    });

    return (data ?? []).map((item) => {
      return {
        ...item,
        // 拼接id，用于唯一标识
        _id: `${item.sku_base?.sku_id}_${item.sku_base?.custom_text_code}`,
      };
    });
  }

  /**
   * 1、普通sku传字符串
   * 2、私人定制sku通过JSON.parse转数组
   * @param skuCode 扫描输入的商品编码
   */
  private tryPaseSkuCode(skuCode: string | string[]): string | string[] {
    if (Array.isArray(skuCode)) {
      return skuCode;
    }

    try {
      const result = JSON.parse(skuCode);
      return Array.isArray(result) ? result : skuCode;
    } catch (error) {
      return skuCode;
    }
  }

  async getSelectedWarehouseId() {
    const { data } = await axios.get<{ warehouse_id: number }>(
      `api/warehouse/setting/default`,
    );

    return data.warehouse_id;
  }

  async setSelectedWarehouseId(id: number) {
    await axios.put(`api/warehouse/setting/default`, {
      warehouse_id: id,
    });
  }
}

export const skuService = new SkuService();
