import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import {
  HttpServerError,
  InvalidParamsError,
  UnauthenticatedError,
  UnauthorizedError,
} from './errors';
import { RouteConfig } from './route';

const IGNORE_ERRORS = [
  // 服务端错误，无需上报
  HttpServerError,
  // 认证失败的错误，无需上报
  UnauthenticatedError,
  // 提交参数错误，无需上报
  InvalidParamsError,
  // 权限不足的错误，无需上报
  UnauthorizedError,
];

export function setupSentry(routes: RouteConfig[]) {
  /**
   * [与 React Router 6.4 Data API 用法](https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/#usage-with-react-router-64-data-api)
   */
  let createRouter = createBrowserRouter;

  // 应用脚本加载成功后就可以关闭 index.html 中的 Sentry 了
  if (window.Sentry && window.__IS_SENTRY_BROWSER_SDK_READY__) {
    window.Sentry.onLoad(() => {
      window.Sentry?.close();
    });
  }

  if (document.head.dataset.sentryEnvironment === 'production') {
    Sentry.init({
      release: process.env.NX_APP_VERSION,
      dsn: process.env.NX_SENTRY_DSN,
      integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        Sentry.replayIntegration(),
      ],
      beforeSend(event, hint) {
        for (const errorClass of IGNORE_ERRORS) {
          if (hint.originalException instanceof errorClass) {
            return null;
          }
        }

        return event;
      },

      ignoreErrors: [
        // 无网络链接，完全匹配
        /^Network Error$/,
        // 连接超时，包含 timeout 的都视为超时
        'timeout',
        // Antd Table 经常会报的一个错误
        // 详见 https://juejin.cn/post/7262623363700981797
        'ResizeObserver loop',
      ],

      tracesSampleRate: 0.2,

      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^\//],

      // 100% 记录带有错误的回放数据
      // 目前每个月只有 500 个回放，需要配置错误过滤机制小心的筛选有用的错误告警信息
      replaysOnErrorSampleRate: 1.0,
    });

    createRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

    window.__IS_SENTRY_REACT_SDK_READY__ = true;
  }

  const router = createRouter(routes);

  return { router };
}
