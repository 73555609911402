import { PrimaryKey } from '@erp-mobile/types';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import {
  WarehouseStackingGetBestPathPayload,
  WarehouseStackingGetBestPathResponse,
  WarehouseStackingPayload,
  WarehouseStackingSkuDto,
} from './warehouse-stacking.dto';
import { warehouseStackingService } from './warehouse-stacking.service';

/**
 * 点击按钮，获取调拨出库任务
 */
export const useGetSku = () => {
  return useMutation({
    mutationFn: async (params: {
      sku_code: string;
      warehouse_id: PrimaryKey;
      type: string;
    }): Promise<WarehouseStackingSkuDto> => {
      return await warehouseStackingService.getSku(params);
    },
  });
};

/**
 * 点击去上架按钮，获取最佳路径
 */
export const useGetBestPath = () => {
  return useMutation({
    mutationFn: async (
      params: WarehouseStackingGetBestPathPayload,
    ): Promise<WarehouseStackingGetBestPathResponse> => {
      return await warehouseStackingService.getBestPath(params);
    },
  });
};

/**
 * 库内上架
 */
export const useStacking = () => {
  return useMutation({
    mutationFn: async (params: WarehouseStackingPayload) => {
      return await warehouseStackingService.stacking(params);
    },
  });
};

/**
 * 单品、多品、爆款配货、调拨入库、库内上架模块，记住仓库选择
 * 获取选中的仓库id，同时设置选中的仓库id
 */
export const useWarehouseId = () => {
  const [warehouseId, setWarehouseId] = useState<number>();

  useEffect(() => {
    warehouseStackingService.getSelectedWarehouseId().then((id) => {
      if (id) {
        setWarehouseId(id);
      }
    });
  }, []);

  /**
   * 保存选中的仓库id
   */
  const saveWarehouseId = async (id: number) => {
    await warehouseStackingService.setSelectedWarehouseId(id);
    setWarehouseId(id);
  };

  return {
    warehouseId,
    setWarehouseId,
    saveWarehouseId,
  };
};
