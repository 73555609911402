import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo } from 'react';
import { useUserProfile } from './user-context';
import { PasswordCredentialDto, UserRole } from './user.dto';
import * as UserKeys from './user.keys';
import { userService } from './user.service';

/**
 * 用户登录 Hook
 * @returns
 */
export const useLogin = () => {
  return useMutation({
    mutationFn: async (data: PasswordCredentialDto) => {
      return userService.login(data);
    },
  });
};

/**
 * 当用户登出后需要清理用户信息缓存
 */
export const useAutoClearProfile = () => {
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.removeQueries({
      queryKey: UserKeys.PROFILE,
      exact: true,
    });
  }, [queryClient]);
};

/**
 * 用户登出 Hook
 */
export const useLogout = () => {
  return useCallback(() => {
    userService
      .logout()
      // WHY: 只是通知服务端用户已退出，不管成功与否均可
      .catch();
  }, []);
};

/**
 * 获取我的信息
 *
 * @returns 我的信息
 */
export const useMyProfile = () => {
  return useQuery({
    queryKey: UserKeys.PROFILE,
    queryFn: async () => {
      const data = await userService.getUserProfile();

      return data;
    },
    /**
     * 不需要缓存，每次都要重新获取最新的
     */
    cacheTime: 0,
  });
};

/**
 * 获取我的权限码
 * @returns
 */
export function useMyAuthorities() {
  const { permissions } = useUserProfile();

  return useMemo(() => {
    return permissions ?? [];
  }, [permissions]);
}

/**
 * 获取用户下所有角色
 *
 * @returns 角色列表
 */
export const useMyRoles = () => {
  const query = useQuery({
    queryKey: UserKeys.ALLUSERROLE,
    queryFn: async (): Promise<UserRole[]> => {
      return await userService.getRoles();
    },
  });

  return query;
};
