import { JobModifyPermissions } from '@/features/modify-job';
import { ModifyPositionPermissions } from '@/features/modify-position';
import { InboundStackingPermissions } from '@/features/put-on-shelves';
import { SignaturePermissions } from '@/features/signature';
import { SkuPermission } from '@/features/sku';
import { DistributionPermission } from '@/features/storage/distribution-task';
import { SignTrackingNumbersPermission } from '@/features/storage/sign-tracking-numbers';
import { TransferOutboundPermission } from '@/features/transfer-outbound';
import { UserKeys, userService } from '@/features/user';
import { RouteConfig } from '@erp-mobile/core';
import { defer } from 'react-router-dom';
import { queryClient } from './app';
import LimitMobile from './core/limit-mobile';
import { InventoryTaskPermission } from './features/inventory-task';
import { ManualInventoryPermission } from './features/manual-inventory';
import { OutboundTaskRegistrationPermission } from './features/outbound-task-registration';
import { TransferInboundPermission } from './features/transfer-inbound';
import { WarehouseStackingPermission } from './features/warehouse-stacking';
import Layouts from './layouts';
import { AuthenticationGuard } from './layouts/authentication-guard';
import { MenuCustomAuthority } from './layouts/authorization/custom-authorization';
import NotFoundPage from './pages/404';
import ErrorPage from './pages/error';

export const routes: RouteConfig[] = [
  {
    element: <LimitMobile></LimitMobile>,
    errorElement: <ErrorPage></ErrorPage>,
    children: [
      {
        path: '/user',
        children: [
          {
            path: 'login',
            handle: {
              name: '登录',
            },
            async lazy() {
              const { default: Component } = await import(
                './pages/login/login'
              );
              return { Component };
            },
          },
        ],
      },
      {
        path: '/',
        element: <AuthenticationGuard></AuthenticationGuard>,
        loader: async () => {
          return defer({
            user: queryClient.fetchQuery({
              queryKey: UserKeys.PROFILE,
              queryFn: () => {
                return userService.getUserProfile();
              },
            }),
          });
        },
        shouldRevalidate: () => false,
        children: [
          {
            element: <Layouts></Layouts>,
            children: [
              {
                index: true,
                handle: {
                  name: '首页',
                },
                async lazy() {
                  const { default: Component } = await import(
                    './pages/home/home'
                  );
                  return { Component };
                },
              },
              // 快递签收
              {
                path: 'tracking-numbers-sign',
                async lazy() {
                  const { default: Component } = await import(
                    './pages/sign-tracking-numbers'
                  );
                  return { Component };
                },
                handle: {
                  authorities: [SignTrackingNumbersPermission.Read],
                  name: '快递签收',
                },
              },
              // 入库上架
              {
                path: 'inbound-stacking',
                async lazy() {
                  const { default: Component } = await import(
                    './pages/inbound-stacking'
                  );
                  return { Component };
                },
                handle: {
                  authorities: [InboundStackingPermissions.Read],
                  name: '入库上架',
                },
              },
              {
                path: 'multi-distribution',
                async lazy() {
                  const { default: Component } = await import(
                    './pages/multi-distribution'
                  );
                  return { Component };
                },
                handle: {
                  authorities: [DistributionPermission.MultiRead],
                  name: '多品配货',
                },
              },
              {
                path: 'single-distribution',
                async lazy() {
                  const { default: Component } = await import(
                    './pages/single-distribution/single-distribution'
                  );
                  return { Component };
                },
                handle: {
                  authorities: [DistributionPermission.SingleRead],
                  name: '单品配货',
                },
              },
              // 爆款配货
              {
                path: 'hot-distribution',
                async lazy() {
                  const { default: Component } = await import(
                    './pages/hot-distribution'
                  );
                  return { Component };
                },
                handle: {
                  authorities: [DistributionPermission.爆款配货],
                  name: '爆款配货',
                },
              },
              {
                path: 'modify-position',
                children: [
                  // 手动修改仓位
                  {
                    path: 'manual',
                    handle: {
                      authorities: [ModifyPositionPermissions.Read],
                      name: '手动修改仓位',
                    },
                    async lazy() {
                      const { default: Component } = await import(
                        './pages/modify-position/manual/manual'
                      );
                      return { Component };
                    },
                  },
                  // 任务修改仓位
                  {
                    path: 'modify',
                    handle: {
                      authorities: [JobModifyPermissions.Read],
                      name: '任务修改仓位',
                    },
                    async lazy() {
                      const { default: Component } = await import(
                        './pages/modify-position/modify'
                      );
                      return { Component };
                    },
                  },
                ],
              },
              // 调拨出库
              {
                path: 'transfer-outbound',
                async lazy() {
                  const { default: Component } = await import(
                    './pages/transfer-outbound'
                  );
                  return { Component };
                },
                handle: {
                  authorities: [TransferOutboundPermission.Read],
                  name: '调拨出库',
                },
              },
              {
                path: 'storage-in/signature',
                async lazy() {
                  const { default: Component } = await import(
                    './pages/signature/signature'
                  );
                  return { Component };
                },
                handle: {
                  authorities: [SignaturePermissions.Read],
                  name: '入库签收',
                },
              },
              {
                path: 'sku-info',
                async lazy() {
                  const { default: Component } = await import(
                    './pages/sku-info'
                  );
                  return { Component };
                },
                handle: {
                  authorities: [SkuPermission.Read],
                  name: '商品信息查询',
                },
              },
              {
                path: 'transfer-inbound',
                async lazy() {
                  const { default: Component } = await import(
                    './pages/transfer-inbound'
                  );
                  return { Component };
                },
                handle: {
                  authorities: [TransferInboundPermission.调拨入库],
                  customAuthority: MenuCustomAuthority.调拨签收流程2,
                  name: '调拨入库',
                },
              },
              {
                path: 'inventory-task',
                async lazy() {
                  const { default: Component } = await import(
                    './pages/inventory-task'
                  );
                  return { Component };
                },
                handle: {
                  authorities: [InventoryTaskPermission.任务盘点],
                  name: '任务盘点',
                },
              },
              {
                path: 'manual-inventory',
                async lazy() {
                  const { default: Component } = await import(
                    './pages/manual-inventory'
                  );
                  return { Component };
                },
                handle: {
                  authorities: [ManualInventoryPermission.手动盘点],
                  name: '手动盘点',
                },
              },
              // 退包上架
              {
                path: 'return-package-stacking',
                async lazy() {
                  const { default: Component } = await import(
                    './pages/return-package-stacking'
                  );
                  return { Component };
                },
                handle: {
                  authorities: [InboundStackingPermissions.查看退包上架],
                  name: '退包上架',
                },
              },
              // 库内上架
              {
                path: 'warehouse-stacking',

                children: [
                  {
                    async lazy() {
                      const { default: Component } = await import(
                        './pages/warehouse-stacking'
                      );
                      return { Component };
                    },
                    handle: {
                      authorities: [WarehouseStackingPermission.库内上架],
                      name: '库内上架',
                    },
                    index: true,
                  },
                  //分拣
                  {
                    path: 'picking',
                    async lazy() {
                      const { default: Component } = await import(
                        './pages/warehouse-stacking/picking'
                      );
                      return { Component };
                    },
                    handle: {
                      authorities: [WarehouseStackingPermission.库内上架],
                      name: '分拣',
                    },
                  },
                  //上架
                  {
                    path: 'stacking',
                    async lazy() {
                      const { default: Component } = await import(
                        './pages/warehouse-stacking/stacking'
                      );
                      return { Component };
                    },
                    handle: {
                      authorities: [WarehouseStackingPermission.库内上架],
                      name: '上架',
                    },
                  },
                ],
              },
              // 发货任务登记
              {
                path: 'shpping-task-registration',
                async lazy() {
                  const { default: Component } = await import(
                    './pages/outbound-task-registration'
                  );
                  return { Component };
                },
                handle: {
                  authorities: [
                    OutboundTaskRegistrationPermission.查看发货任务登记,
                  ],
                  name: '发货任务登记',
                },
              },
            ],
          },
        ],
      },
      {
        path: '/*',
        element: <NotFoundPage></NotFoundPage>,
      },
    ],
  },
];
