import { useMutation, useQuery } from '@tanstack/react-query';
import { SystemSettings } from './system-settings.dto';
import { systemSettingsService } from './system-settings.service';

export const SYSTEM_SETTINGS = ['WAREHOUSE_SYSTEM_SETTINGS'];

export const useSystemSettings = () => {
  return useQuery({
    queryKey: SYSTEM_SETTINGS,
    queryFn: async () => {
      return systemSettingsService.get();
    },
    staleTime: 1000 * 3,
  });
};

export const useUpdateSystemSettings = () => {
  return useMutation({
    mutationFn: async (data: SystemSettings) => {
      return systemSettingsService.update(data);
    },
  });
};
