import { BooleanNumber, Pagination, PrimaryKey } from '@erp-mobile/types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
// import { notification } from 'antd';
import { useMemo } from 'react';
import {
  WarehouseGroup,
  WarehouseItem,
  WarehouseOptionParams,
} from './warehouse-setting.dto';
import { CreateGroup, CreateWarehouse } from './warehouse-setting.entity';
import {
  ALL_GROUP,
  COUNT_TOTAL,
  COUNT_VALUE_PAGE,
  ENTITY,
  LIST,
  OPTIONS,
  POSITIONS_BY_WAREHOUSE,
} from './warehouse-setting.key';
import { warehouseSettingService } from './warehouse-setting.service';

// 获取仓库分组列表
export const useWarehouseGroupList = () => {
  const query = useQuery({
    queryKey: ALL_GROUP,
    queryFn: async (): Promise<WarehouseGroup[]> => {
      return await warehouseSettingService.getAllGroup();
    },
  });
  const { data } = query;

  const options = useMemo(() => {
    return data?.map((item) => ({ value: item.id, label: item.title }));
  }, [data]);

  return { ...query, options };
};

/**
 * 批量修改仓库分组
 */
export const useUpdateGroup = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({
      id,
      warehouse_group_id,
    }: {
      id: number[];
      warehouse_group_id: PrimaryKey;
    }): Promise<void> => {
      await warehouseSettingService.updateGroup({ id, warehouse_group_id });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: LIST,
      });
    },
  });

  return mutation;
};

/**
 * 创建仓库分组
 */
export const useCreateGroup = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (params: CreateGroup): Promise<void> => {
      await warehouseSettingService.createGroup(params);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ALL_GROUP,
      });
    },
  });

  return mutation;
};

/**
 * 删除仓库分组
 */
export const useRemoveGroup = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async (id: PrimaryKey): Promise<void> => {
      await warehouseSettingService.removeGroup(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ALL_GROUP,
      });
      queryClient.invalidateQueries({
        queryKey: LIST,
      });
    },
  });

  return mutation;
};

/**
 * 创建仓库
 */
export const useCreateWarehouse = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (params: CreateWarehouse): Promise<void> => {
      await warehouseSettingService.createWarehouse(params);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: LIST,
      });
    },
  });

  return mutation;
};

/**
 * 创建仓库
 */
export const useUpdateWarehouse = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (
      params: CreateWarehouse & { id: PrimaryKey },
    ): Promise<void> => {
      await warehouseSettingService.updateWarehouse(params);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: LIST,
      });
    },
  });

  return mutation;
};

// 获取指定仓库
export const useWarehouseInfo = (
  id?: PrimaryKey,
  options?: {
    onSuccess?: (data: WarehouseItem) => void;
  },
) => {
  const query = useQuery({
    queryKey: ENTITY(id),
    queryFn: async (): Promise<WarehouseItem> => {
      return await warehouseSettingService.getInfo(id);
    },
    enabled: !!id,
    onSuccess: options?.onSuccess,
  });

  return query;
};

/**
 * 修改仓库状态
 */
export const useUpdateStatus = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async ({
      id,
      is_publish,
    }: {
      id: PrimaryKey;
      is_publish: BooleanNumber;
    }): Promise<void> => {
      await warehouseSettingService.updateStatus({ id, is_publish });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: LIST,
      });
    },
  });

  return mutation;
};

/**
 * 设置默认仓库
 */
export const useSetDefault = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async (id: PrimaryKey): Promise<void> => {
      await warehouseSettingService.setDefault(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: LIST,
      });
    },
  });

  return mutation;
};

/**
 * 获取价值详情列表
 */
export const useCountValueInfo = (
  queryData: Pagination & { warehouse_id: PrimaryKey },
) => {
  const { data, ...rest } = useQuery({
    queryKey: COUNT_VALUE_PAGE(queryData),
    queryFn: async () => {
      return warehouseSettingService.getValueList(queryData);
    },
    keepPreviousData: true,
    enabled: !!queryData.warehouse_id,
  });

  return {
    ...rest,
    total: data?.meta.total,
    data: data?.data,
  };
};

/**
 * 获取价值详情汇总
 */
export const useCountValueInfoTotla = (warehouse_id: PrimaryKey) => {
  const query = useQuery({
    queryKey: COUNT_TOTAL(warehouse_id),
    queryFn: async () => {
      return warehouseSettingService.getValueTotla(warehouse_id);
    },
    keepPreviousData: true,
    enabled: !!warehouse_id,
  });

  return query;
};

/**
 * 获取仓库options
 */
export const useWarehouseOptions = (
  params: WarehouseOptionParams,
  enabled = true,
) => {
  const { data, ...rest } = useQuery({
    queryKey: OPTIONS,
    queryFn: async () => {
      return warehouseSettingService.getWarehouseOptions(params);
    },
    enabled,
  });

  const options = useMemo(() => {
    return data?.map((item) => ({
      value: item.id,
      label: item.title,
      ...item,
    }));
  }, [data]);

  return {
    ...rest,
    data,
    options,
  };
};

/**
 * 通过仓库获取仓位
 */
export const usePositionsByWarehouse = (id?: PrimaryKey, value?: string) => {
  const { data, ...rest } = useQuery({
    queryKey: POSITIONS_BY_WAREHOUSE({ id, value }),
    queryFn: async () => {
      return warehouseSettingService.getWarehousePositions(id, value);
    },
    enabled: !!id,
  });

  const options = useMemo(() => {
    return data?.map((item) => ({ value: item.id, label: item.code }));
  }, [data]);

  return {
    ...rest,
    data,
    options,
  };
};
