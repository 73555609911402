import { BooleanNumber, PrimaryKey } from '@erp-mobile/types';

/**
 * 仓库分组数据
 */
export interface WarehouseGroup {
  title: string;
  id: PrimaryKey;
  created_at_gmt: string;
  updated_at_gmt: string;
  num: number;
}

/**
 * 操作日志
 */
export interface LogItem {
  id: PrimaryKey;
  action_id: PrimaryKey;
  action_desc: string;
  description: string;
  model_id: PrimaryKey;
  operator_user_id: PrimaryKey;
  created_at_gmt: string;
  updated_at_gmt: string;
  operator_name: string;
}

/**
 * 仓库option
 */
export type WarehouseOptionItem = Pick<
  WarehouseItem,
  'id' | 'title' | 'province' | 'city' | 'area' | 'address'
>;

export enum WarehouseOptionIsPublish {
  启用 = '1',
  停用 = '0',
}

export enum WarehouseOptionIsDataPermissions {
  不按数据权限展示 = '1',
  按数据权限展示 = '2',
  按商品权限展示 = '3',
}

/**
 * 获取仓库options参数
 */
export interface WarehouseOptionParams {
  // 不传默认全部 1启用 0停用
  is_publish?: WarehouseOptionIsPublish;
  // 默认1:不按数据权限展示   2:按数据权限展示
  is_data_permissions: WarehouseOptionIsDataPermissions;
}

/**
 * 仓库列表数据
 */
export interface WarehouseItem {
  id: PrimaryKey;
  title: string;
  /**
   * 仓库编码
   */
  code: string;
  /**
   * 城市
   */
  city: string;
  desc: string;
  /**
   * 区域
   */
  area: string;
  phone: string;
  address: string;
  province: string;
  zip_code: string;
  /**
   * 货架数
   */
  shelf_num: number;
  is_publish: BooleanNumber;
  is_default: BooleanNumber;
  contact_tel: string;
  inventory_type: number;
  created_at_gmt: string;
  updated_at_gmt: string;
  /**
   * 库存总量
   */
  inventory_total: number;
  /**
   * 库存价值
   */
  inventory_value: number;
  /**
   * 在途价值
   */
  in_transit_value: number;
  contacts_user_id: number;
  /**
   * 分组ID
   */
  warehouse_group_id: PrimaryKey;
  warehouse_group_name: string;
  single_item_delivery_max: number;
  multiple_item_delivery_max: number;
}

/**
 * 库存价值明细
 */
export interface CountInfo {
  cover_url: string;
  sku_title: string;
  sku_code: string;
  /**
   * 库存
   */
  stock: number;
  /**
   * 成本价
   */
  warehouse_cost: string;
  /**
   * 总价值
   */
  total_price: number;
  id: PrimaryKey;
  sku_id: PrimaryKey;
}

export interface Refresh {
  time: string;
  /**
   * 库存总量
   */
  total_count: number;
  /**
   * 库存价值
   */
  total_price: number;
  /**
   * 在途价值
   */
  total_transit: number;
}

export interface CountInfoTotal {
  /**
   * 总价值
   */
  total_price: number;
  /**
   * 库存总类
   */
  category_num: number;
  /**
   * 库存总量
   */
  total_count: number;
}

export interface WarehousePositions {
  id: PrimaryKey;
  /**
   * 仓位编码
   */
  code: string;
  /**
   * 货架id
   */
  warehouse_shelve_id: PrimaryKey;
  column: number;
  row: number;
  created_at_gmt: string;
  updated_at_gmt: string;
}
