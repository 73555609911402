import { BooleanNumber, PrimaryKey } from '@erp-mobile/types';

export interface SyncPositionData {
  /**
   * sku id
   */
  sku_id: PrimaryKey;
  /**
   * 拓展属性编码
   */
  custom_text_code: string;
  /**
   * 拓展属性
   */
  custom_text: string;
  /**
   * 商品SKU
   */
  sku_code: string;
}
export interface ChangePosition {
  sku_code: string;
  sku_id: PrimaryKey;
  warehouse_shelf_positions_id_old: PrimaryKey;
  warehouse_shelf_positions_id: PrimaryKey;
  warehouse_id: PrimaryKey;
  /**
   * 拓展属性编码
   */
  custom_text_code?: string;
  /**
   * 拓展属性
   */
  custom_text?: string;
  /**
   * 是否同步仓位
   * 0: 不同步
   * 1: 同步
   */
  is_sync_position?: BooleanNumber;
  /**
   * 同步仓位数据
   */
  sync_position_data?: SyncPositionData[];
}

export interface FinishedInbound {
  id: PrimaryKey;
  wait_received_num?: number;
  this_received_num?: number;
  bad_received_num?: number;
  task_code: string;
  'User-Agent': string;
  position_code: string;
}

export enum InboundStackingTaskType {
  入库上架 = 1,
  退包上架 = 2,
}
