import { BooleanNumber, Pagination, PrimaryKey } from '@erp-mobile/types';
import { QueryParams } from './warehouse-setting.entity';

/**
 * 仓库分组列表的查询 Key
 */
export const GROUP = ['WAREHOUSE_GROUP'] as const;
/**
 * 全部仓库分组
 */
export const ALL_GROUP = [...GROUP, 'ALL'];

/**
 * 仓库列表查询Key
 */
export const LIST = ['WAREHOUSE'];

export const PAGE = (queryParams: QueryParams & Pagination) => [
  ...LIST,
  'PAGE',
  queryParams,
];

/**
 * 仓库options Key
 */
export const OPTIONS = [...LIST, 'OPTIONS'];

/**
 * 操作日志列表查询Key
 */
export const LOGLIST = ['LOGLIST'];

export const LOGENTITY = (queryParams: { id: PrimaryKey } & Pagination) => [
  ...LOGLIST,
  queryParams,
];

/**
 * 指定仓库
 */
export const ENTITY = (id?: number) => [...LIST, id] as const;

/**
 * 库存价值明细Key
 */
export const COUNT_VALUE = ['COUNT_VALUE'];

export const COUNT_VALUE_PAGE = ({
  warehouse_id,
  ...queryParams
}: Pagination & { warehouse_id: PrimaryKey }) => [
  ...LIST,
  warehouse_id,
  queryParams,
];

export const COUNT_TOTAL = (id?: PrimaryKey) => ['COUNT_TOTAL', id];

/**
 * 手动刷新 Key
 */
export const REFRESH = ['REFRESH'] as const;

export const REFRESH_TYPE = (data: {
  is_refresh: BooleanNumber;
  title: string;
}) => {
  return [...REFRESH, data];
};

/**
 * 仓位查询 key
 */

export const POSITIONS = ['POITIONS'];

export const POSITIONS_BY_WAREHOUSE = (data: {
  id?: PrimaryKey;
  value?: string;
}) => [...POSITIONS, 'BY_WAREHOUSE', data];
