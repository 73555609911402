import axios from 'axios';
import {
  TransferInboundDto,
  TransferInboundPayload,
} from './transfer-inbound.dto';

/**
 * 调拨出库
 */
export class TransferInboundService {
  /**
   * 获取调拨入库
   */
  async getTask(sign_no: string) {
    if (!sign_no) {
      return [];
    }

    const { data } = await axios.get<TransferInboundDto[]>(
      'api/transfer_order/inbound/transfer',
      {
        params: {
          sign_no,
        },
      },
    );
    return data;
  }

  /**
   * 完收入库
   */
  async inbound(data: TransferInboundPayload[]) {
    await axios.post('api/transfer_order/inbound/transfer', {
      data,
    });
  }
}

export const transferInboundService = new TransferInboundService();
