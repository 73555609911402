import { lfq, TrackEvent } from '@erp/lfq';
import { isArray, isString } from 'lodash-es';

/**
 * 出库扫码拼接
 * @param code 手动输入或扫码输入的字符串
 *
 * 后两个可选参数只针对手动修改数量时使用
 * @param custom_text_code 拓展属性转码
 * @returns 拼接后的JSON字符串 ['SKU编码','拓展属性转码']
 */
export const codeSplice = (code: string, custom_text_code?: string) => {
  //  code为空时不进行后续逻辑
  if (!code) return '';

  if (!isString(code)) {
    lfq.track(TrackEvent.badSkuCodeError, { code });
  }

  const stringCode = code.toString();

  if (custom_text_code) {
    const jsonObj = [stringCode.trim(), custom_text_code];
    return JSON.stringify(jsonObj);
  } else {
    try {
      const parsedCode = JSON.parse(stringCode);
      return isArray(parsedCode)
        ? stringCode
        : JSON.stringify([stringCode.trim()]);
    } catch (error) {
      return JSON.stringify([stringCode.trim()]);
    }
  }
};
/**
 * 扫码sku可能是json字符串，需要做parse后取第一项
 * @param sku sku码
 * @returns 转换后的 sku 码
 * @example ['sku123', 'xxx'] => 'sku123'
 */
export const parseSku = (sku: string) => {
  try {
    const res = JSON.parse(sku);
    return isArray(res) ? res[0] : res;
  } catch (error) {
    return sku;
  }
};
/**
 * 排序value处理
 */
export const getFilterValue = (value: string) => {
  try {
    const filterValue: string[] = JSON.parse(value);
    return filterValue.length > 1
      ? `${filterValue[0]}@@@${filterValue[1]}`
      : filterValue[0];
  } catch (error) {
    return value;
  }
};
