import axios from 'axios';
import { SystemSettings } from './system-settings.dto';

/**
 * 仓储系统配置 API
 */
export class SystemSettingsService {
  /**
   * 获取仓储系统配置信息
   *
   * @returns 配置信息
   */
  async get() {
    const { data } = await axios.get<SystemSettings>('api/options/wms');
    return data;
  }

  /**
   * 更新仓储系统配置信息
   */
  async update(data: SystemSettings) {
    await axios.put('api/options/wms', data);
  }
}

export const systemSettingsService = new SystemSettingsService();
