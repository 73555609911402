import { FC, PropsWithChildren, useEffect, useRef } from 'react';

/**
 * 页面容器
 * 1、不用 100vh，因为有些手机会有底部导航栏，会遮挡页面
 * 2、通过监听 resize 事件，动态设置高度
 * Why：minHeight 560
 * 1、手机端弹出键盘时，会导致页面高度被压缩
 * 2、手机端 PDA 的高度一般 560
 */
export const PageContainer: FC<PropsWithChildren> = ({ children }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const onResize = () => {
      if (ref.current) {
        ref.current.style.height = `${window.innerHeight}px`;
      }
    };

    onResize();
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return (
    <div
      ref={ref}
      style={{
        minHeight: 560,
        background:
          'linear-gradient(180deg, #fff 0%, #fff 6.93%, #f0f0f5 13.33%)',
      }}
      className="flex flex-col"
    >
      {children}
    </div>
  );
};
