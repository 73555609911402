import { Container } from '@erp-mobile/components';
import { TrackEvent, lfq } from '@erp/lfq';
import { Result } from 'antd-mobile';
import { useEffect, useMemo } from 'react';
import { Outlet, useLocation, useMatches } from 'react-router-dom';
import { useErrorHandler } from './error-handler.hook';
import { RouteConfigHandle } from './route';

export default function LimitMobile() {
  useErrorHandler();

  const matchedRoutes = useMatches();
  const location = useLocation();

  useEffect(() => {
    const routesWithName = matchedRoutes.filter(
      (item) => (item.handle as RouteConfigHandle | undefined)?.name != null,
    );
    const route = routesWithName[routesWithName.length - 1];
    const title =
      (route?.handle as RouteConfigHandle | undefined)?.name ?? 'ERP Mobile';

    document.title = title;
  }, [matchedRoutes]);

  useEffect(() => {
    lfq.track(TrackEvent.pageView);
    lfq.track(TrackEvent.location, location);
  }, [location]);

  // 请注意  该组件无法 ssr
  const isMobile = useMemo(() => {
    return navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i,
    );
  }, [location.pathname]);

  if (!isMobile) {
    return (
      <Container>
        <Result status="info" title="请使用移动端登录本系统"></Result>
      </Container>
    );
  }
  return <Outlet></Outlet>;
}
