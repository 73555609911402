import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import {
  TransferInboundDto,
  TransferInboundItemDto,
  TransferInboundPayload,
  UpdatePositionPayload,
} from './transfer-inbound.dto';
import {
  TRANSFER_INBOUND,
  TRANSFER_INBOUND_TASK,
} from './transfer-inbound.keys';
import { transferInboundService } from './transfer-inbound.service';

/**
 * 获取调拨入库任务
 */
export const useTransferInboundTask = (sign_no: string) => {
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey: TRANSFER_INBOUND_TASK(sign_no),
    queryFn: async () => {
      return await transferInboundService.getTask(sign_no);
    },
    enabled: false,
    cacheTime: 0,
  });

  const toShowData = (originData: TransferInboundDto[] | undefined) => {
    if (!originData) {
      return [];
    }

    const result: TransferInboundItemDto[] = [];

    originData.forEach((item) => {
      const { data: skus, ...rest } = item;
      result.push({
        ...rest,
        rowKey: rest.transfer_batch,
        isBatchRow: true,
      });

      (skus ?? []).forEach((sku) => {
        result.push({
          ...sku,
          rowKey: `${rest.transfer_batch}-${sku.id}`,
          isBatchRow: false,
          transfer_batch: rest.transfer_batch,
        });
      });
    });

    return result;
  };

  const { data } = query;
  const showData: TransferInboundItemDto[] = useMemo(() => {
    return toShowData(data);
  }, [data]);

  const updateWarehousePostion = (payload: UpdatePositionPayload) => {
    const { batch, id, position } = payload;
    queryClient.setQueryData<TransferInboundDto[]>(
      TRANSFER_INBOUND_TASK(sign_no),
      (old) => {
        if (!old) {
          return [];
        }

        return old.map((item) => {
          if (item.transfer_batch === batch) {
            return {
              ...item,
              data: item.data.map((sku) => {
                if (sku.id === id) {
                  return {
                    ...sku,
                    ...position,
                  };
                }

                return sku;
              }),
            };
          }

          return item;
        });
      },
    );
  };

  return {
    ...query,
    showData,
    updateWarehousePostion,
    toShowData,
  };
};

export const useTransferInbound = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (data: TransferInboundPayload[]): Promise<void> => {
      await transferInboundService.inbound(data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: TRANSFER_INBOUND,
      });
    },
    onError: () => {
      // 失败也去刷新
      queryClient.invalidateQueries({
        queryKey: TRANSFER_INBOUND,
      });
    },
  });

  return mutation;
};
