import { InferType, array, mixed, number, object, string } from 'yup';

export const transferInboundSchema = object({
  list: array().of(
    object({
      inbound_number: number().nullable().required(),
      damaged_number: number().nullable().required().min(0),
      total_inbound_number: number().nullable(),
      remark: string().nullable(),
      rowKey: string().nullable(),
      data: mixed().nullable(),
    }),
  ),
});

export type TransferInboundFormValues = InferType<typeof transferInboundSchema>;
