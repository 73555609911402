import { useMutation, useQuery } from '@tanstack/react-query';
import { AssociatePurchaseOrderPayload } from './sign-tracking-numbers.dto';
import { QUERY_COMPANY_OPTION } from './sign-tracking-numbers.keys';
import { singTrackingNumbersService } from './sign-tracking-numbers.services';

// 扫描快递单后，进行快速签收
export const useSignTrackingNumbers = () => {
  return useMutation({
    mutationFn: async (trackingNumbers: string) => {
      return await singTrackingNumbersService.sign(trackingNumbers);
    },
  });
};

// 异常签收：扫描快递物流单号后，不能找到物流单号关联的采购订单
// 此时需要进行手动关联采购订单
export const useAssociatePurchaseOrder = () => {
  return useMutation({
    mutationFn: async (payload: AssociatePurchaseOrderPayload) => {
      return await singTrackingNumbersService.associatePurchaseOrder(payload);
    },
  });
};

// 获取物流公司option
export const useLogisticCompanyOptions = (title?: string) => {
  return useQuery({
    queryKey: QUERY_COMPANY_OPTION(title),
    queryFn: async () => {
      return singTrackingNumbersService.getLogisticCompanyOptions({ title });
    },
  });
};
