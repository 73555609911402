import { GetSignatureInPayload } from './signature.dto';

export const SIGNATURE = ['SIGNATURE'];

export const SIGNATURE_ALL = (payload?: GetSignatureInPayload) =>
  [...SIGNATURE, { ...payload }] as const;

export const SIGNATURE_REPORT = (payload: { purchase_number: string }) => [
  'SIGNATURE_REPORT',
  { ...payload },
];

// 表示当前采购单
export const SIGNATURE_TYPE_PURCHASE = 'purchase_number';
