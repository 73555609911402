import axios from 'axios';
import {
  CompleteJobPayload,
  CompleteJobResp,
  GetModifyJobPayload,
  ModifyJobItem,
} from './modify-job.dto';

class ModifyJobService {
  async getModifyJob(params: GetModifyJobPayload): Promise<ModifyJobItem[]> {
    const { data } = await axios.get('api/position/position_task/get_task', {
      params,
    });
    return data;
  }

  async doModifyJob(body: CompleteJobPayload): Promise<CompleteJobResp> {
    const { data } = await axios.post(
      `api/position/position_task/complete/${body.id}`,
      body,
    );
    return data;
  }

  /**
   * 扫码sku可能是json字符串，需要做parse后取第一项
   * @param sku sku🐴
   * @returns 转换后的 sku 码
   * @example "[\'sku123\', \'xxx\']" => ["sku123", "xxx"]
   */
  parseSku2Array(sku: string) {
    try {
      const res = JSON.parse(sku);
      return Array.isArray(res) ? res : [res];
    } catch (error) {
      return [sku];
    }
  }
}

export const modifyJobService = new ModifyJobService();
